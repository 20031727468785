<template lang="pug">
.back_six
  .six_box
    .title
      img(src="~@/assets/img/invite_friends/honor_title.png")
    .num
      span 共
      span {{count}}
      span 人参与
    .tab
      .tab-box
        .tab-item(:class="time === 1 ? 'active' : ''", @click="tabChange(1)")
          div 本月榜单
          div {{ timeRange1 }}
        .tab-item(:class="time === 2 ? 'active' : ''", @click="tabChange(2)")
          div 上月榜单
          div {{ timeRange2 }}
      .arrow
        .arrow-item(:class="time === 1 ? 'active' : ''")
        .arrow-item(:class="time === 2 ? 'active' : ''")
    .honor(v-if="dataList")
      .honor-box
        .honor-rela
          img.parent(src="~@/assets/img/invite_friends/honor_2.png")
          img.avatar(:src="dataList[1].headpic")
        .name {{ dataList[1].realname }}
        .mobile {{ dataList[1].mobile }}
        .earn {{dataList[1].success?'已邀请' + dataList[1].success + '人': ''}}
      .honor-box
        .honor-rela
          img.parent(src="~@/assets/img/invite_friends/honor_1.png")
          img.avatar(:src="dataList[0].headpic")
        .name {{ dataList[0].realname }}
        .mobile {{ dataList[0].mobile }}
        .earn {{dataList[0].success?'已邀请' + dataList[0].success + '人': ''}}
      .honor-box
        .honor-rela
          img.parent(src="~@/assets/img/invite_friends/honor_3.png")
          img.avatar(:src="dataList[2].headpic")
        .name {{ dataList[2].realname }}
        .mobile {{ dataList[2].mobile }}
        .earn {{dataList[2].success?'已邀请' + dataList[2].success + '人': ''}}
</template>
<script>

export default {
  data() {
    return {
      time: 1,
      timeRange1: "2021/08/01-08/31",
      timeRange2: "2021/08/01-08/31",
    };
  },
  props: {
    dataList: {
      type: Array,
      default: ()=> {
        return null
      }
    },
    count: {
      type: String,
      default: "0"
    }
  },
  created() {
    this.getTime();
  },
  methods: {
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate()
      this.timeRange1 = `${year}/${month}/1-${month}/${day}`;
      const month2 = month === 1 ? 12 : month - 1;
      const d2 = new Date(year, month2, 0);
      this.timeRange2 = `${year}/${month2}/1-${month2}/${d2.getDate()}`;
    },
    tabChange(e) {
      this.time = e;
      this.$emit('updata', e)
    },

  },
};
</script>
<style lang="less">
.back_six {
  padding: 10px 15px 0;
  .six_box {
    background: #ffffff;
    border-radius: 22px;
    padding-bottom: 20px;
    .title {
      text-align: center;
      img {
        height: 30px;
        width: 178px;
      }
    }
    .honor {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      padding: 0 10px;
      align-items: flex-end;
      .honor-rela {
        position: relative;
      }
      .honor-box {
        text-align: center;
        .name {
          font-size: 12px;
          color: #777777;
          height: 20px;
        }
        .mobile {
          color: #aaaaaa;
          font-size: 10px;
          height: 20px;
        }
        .earn {
          height: 20px;
        }
      }
      .honor-box:nth-of-type(1) {
        .avatar {
          border: 1px solid #8ca4c4;
        }
        .earn {
          font-size: 12px;
          color: #8ca4c4;
        }
      }
      .honor-box:nth-of-type(2) {
        .avatar {
          border: 1px solid #fbbc4d;
        }
        .earn {
          font-size: 12px;
          color: #fbbc4d;
        }
      }
      .honor-box:nth-of-type(3) {
        .avatar {
          border: 1px solid #e3ac79;
        }
        .earn {
          font-size: 12px;
          color: #e3ac79;
        }
      }
      .honor-box:nth-of-type(1),
      .honor-box:nth-of-type(3) {
        .parent {
          width: 85px;
          height: 78px;
          position: relative;
          z-index: 2;
        }
        .avatar {
          height: 52px;
          width: 52px;
          position: absolute;
          left: 50%;
          margin-left: -26px;
          top: 50%;
          margin-top: -30px;
          border-radius: 50%;
          z-index: 1;
        }
      }
      .honor-box:nth-of-type(2) {
        .parent {
          width: 98px;
          height: 86px;
          position: relative;
          z-index: 2;
        }
        .avatar {
          height: 58px;
          width: 58px;
          position: absolute;
          left: 50%;
          margin-left: -29px;
          top: 50%;
          margin-top: -32px;
          border-radius: 50%;
          z-index: 1;
        }
      }
    }
    .num {
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      margin-top: 12px;
      margin-bottom: 20px;
      span:nth-of-type(2) {
        color: #ff5858;
      }
    }
    .intro {
      font-size: 12px;
      color: #777777;
      text-align: center;
    }
    .tab {
      padding: 0 18px;
      .arrow {
        display: flex;
        justify-content: space-around;
        margin-top: -1px;
        height: 10px;
        .arrow-item {
          height: 0;
          width: 0;
          transition: all 0.2s ease-in-out;
        }
        .active {
          border: 5px solid #fe1c30;
          border-left-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
        }
      }
      .tab-box {
        height: 72px;
        border-radius: 36px;
        border: 1px solid #ff5858;
        display: flex;
        text-align: center;
        align-items: center;
        overflow: hidden;
        .active {
          background: #d8d8d8 linear-gradient(180deg, #fd686c 0%, #fe1c30 100%);
          color: #ffffff;
        }
        .tab-item {
          flex: 1;
          height: 100%;
          padding-top: 30px;
          div:first-child {
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
